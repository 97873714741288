<template>
	<div class="order_wrap">
		<MStepper />
		<v-container class="">
			<div class="txt_box  px-6">
				<h1 class="tit_page">수납 (은행 즉시이체)</h1>
				<p class="txt_body1 mb-4 ">
					아래 계좌로 지정된 금액을 입금해주세요.
					<br />수납 금액은 총<span class="primary--text">
						{{ recpInfos.amount | comma }} 원</span
					>
					입니다.
					<br />
					<span class="txt_warn fln">※ 계약자 명의 계좌만 가능</span>
				</p>
			</div>
			<BankPayForm
				ref="bank"
				:hidePaymentDate="true"
				:hideCustType="true"
				:hideBizNo="true"
				:instantTransfer="true"
				:disabledCustName="disableCustType"
			/>
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					{{ recpInfos.amount | comma }}원 수납 진행
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					수납방법 재선택
				</v-btn>
			</div>
			<CustomRecpDialog ref="recpPop" />
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
import { bankInstantTransfer } from '@/apis/order.recp.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import { sendAlimTalk, fetchEContract } from '@/apis/order.ec.api'
import { registerCallbackToApp } from '@/utils/navigation'
export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '' //04:판매인 07:고객수납
			},
			mthd: '11', // 은행이체 - 기타결제
			status: '', // 서명완료 후 재진입
			webOrderSum: {},
			disableCustType: false,
			isPreContract: this.$store.state['verify'].customer.orderType === 'C'
		}
	},
	created() {
		this.initAppPage()
	},
	mounted() {
		const custType = this.$store.getters['verify/customerType']
		this.disableCustType = custType === 'P' ? true : false

		this.recpInfos = this.$store.getters['recp/recpInfos']
		if (!this.recpInfos.mthd) this.recpInfos.mthd = this.mthd

		const { status } = this.$route.query
		if (status) {
			this.status = status
		}
	},
	methods: {
		onResetPayMethod() {
			this.$router.go(-1)
		},
		async chkReturn(prm, rtn, tcObj) {
			this.$log('rtn:', JSON.stringify(rtn))
			if (rtn.TYPE === 'S') {
				if (this.status === '106') {
					// 직접인증 가상계좌 서명완료 후 재진입
					await this.$alert({
						message: '수납이 완료됐습니다.<br />주문을 확정합니다.',
						okText: '주문확정'
					})
				} else {
					await this.$alert({
						message:
							'수납은 전자 계약서 서명 완료 후 진행됩니다.<br />설치정보 화면으로 이동합니다.',
						okText: '설치정보 화면으로 이동'
					})
				}
				// store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				const res = await fetchWebOrderInfoById(mobOrderNo)
				let webParams = res.resultObject
				let order = JSON.parse(webParams.orderInfoJson)
				this.webOrderSum = order.PAY_SUMMARY_AMT

				order = Object.assign(order, {
					recp: {
						recpParams: this.$store.getters['recp/recpParams'],
						recpInfos: this.$store.getters['recp/recpInfos'],
						isCustSelfPay: false,
						recpBankResult: tcObj,
						payRecp: this.$store.getters['recp/payRecp']
					}
				})
				if (this.status === '106') {
					webParams.step = '07' // 주문확정
					webParams.statusCode = this.isPreContract ? '112' : '109' // 주문확정 or 배정대기
				} else {
					webParams.step = '05' // 설치정보
					webParams.statusCode = '107' // 설치대기
				}
				webParams.orderInfoJson = JSON.stringify(order)

				const postRes = await postWebOrderInfoById(mobOrderNo, webParams)
				this.$log('postRes: ', JSON.stringify(postRes))
				if (this.status === '106') {
					// 진행현황
					// 알림톡 발송
					let ecParams = {
						mobOrderNo: this.$store.getters['verify/mobOrderNo']
					}
					const ecRes = await fetchEContract(ecParams)
					const orderList = ecRes.resultObject.data[0].setup
					let prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
					let planDt = orderList[0].FIRST_DT ?? ''
					let count =
						orderList && orderList.length > 1 ? orderList.length - 1 : ''
					let ordNoStr = ''
					orderList.forEach((res, i) => {
						if (i === 0) {
							ordNoStr = `${res.ORDER_NO}`
						} else {
							ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
						}
					})
					let sumAmt = ecRes.resultObject.data[0].sumAmt
					sumAmt.MONTH_AMT_DC = this.$store.getters['pay/monthAmtDc']
					sumAmt.MONTH_AMT_TOTAL = sumAmt.MONTH_AMT - sumAmt.MONTH_AMT_DC

					let atParams = {
						reqCd: this.isPreContract ? '6' : '2', // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
						gubun: this.custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
						name: this.$store.getters['verify/custName'],
						phone: this.$store.getters['verify/custPhone'],
						orderNo: ordNoStr,
						url: `${process.env.VUE_APP_FRT_URL}/order/confirm`, // 주문확정 링크
						orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
						customerNo: this.$store.getters['verify/custNo'],
						codyNo: this.$store.getters['common/codyNo'],
						codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
						goodsNm: prdNm,
						planDt: planDt,
						goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
						onceAmt: this.$options.filters.comma(
							this.webOrderSum.totalSumOfRecpAmt
						),
						monthAmt: this.$options.filters.comma(
							this.webOrderSum.totalSumOfMonthAmt
						)
					}

					await sendAlimTalk(atParams)

					this.$router.push({ name: 'order-status' })
				} else {
					// 설치정보
					this.$router.push({ name: 'order-inst' })
				}
			} else {
				const options = {
					title: '다음과 같은 사유로 수납이 거부됐습니다.',
					guide: '수납방법을 재선택 바랍니다.',
					contents: {
						거부사유: rtn.MESSAGE
					},
					okText: '수납방법 재선택',
					cancelText: ''
				}

				const popRes = await this.$refs.recpPop.open(options)

				if (popRes) {
					// 수납방법 재선택
					this.onResetPayMethod()
				}
			}
		},
		async goRecp() {
			const formData = this.$refs.bank.onSubmit()
			if (!formData) return
			this.$log('bank formData:', formData)

			let payRecp = {
				AF_OWNER_NM: formData.custName,
				AF_BANK_CD_NM: formData.bankNm,
				AF_CARD_NO: formData.accountNo
			}

			this.$store.dispatch('recp/setPayRecp', payRecp)

			const options = {
				title: '다음 계좌를 통해 즉시이체를 요청합니다.',
				guide: '즉시 이체는 계약서 서명 후 진행됩니다.',
				contents: {
					예금주: formData.custName,
					생년월일: formData.ssn,
					수납방법: `${formData.bankNm} 
					${this.$options.filters.star(formData.accountNo, 4)}`,
					수납금액: `${this.$options.filters.comma(this.recpInfos.amount)}원`
				},
				okText: '즉시이체',
				cancelText: '수납방법 재선택'
			}

			const popRes = await this.$refs.recpPop.open(options)

			if (popRes) {
				let prm = {
					bankc: formData.bankCd,
					suacc: formData.accountNo,
					resty: this.recpInfos.custType,
					resId: formData.ssn,
					depositor: formData.custName,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					mobOrderNo: this.$store.state['verify'].mobOrderNo,
					zwebRecp: '04'
				}

				// if (this.status && this.status === '106') {
				// 서명완료 후 재진입시 은행이체면 바로 api 호출 -->> 원복 04 호출
				const res = await bankInstantTransfer(prm)
				const rtn = res.resultObject.data.E_RETURN
				await this.chkReturn(prm, rtn, res.resultObject.data.TC_BKACCT[0])
				// } else {
				// 	// 은행즉시이체는 api 생략 - 서명 후 은행이체 api 호출
				// 	const rtn = {
				// 		TYPE: 'S'
				// 	}
				// 	await this.chkReturn(prm, rtn, undefined)
				// }
			} else {
				// 수납방법 재선택
				this.onResetPayMethod()
			}
		},
		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},
		cbPhysicalBack() {
			this.onResetPayMethod()
		}
	}
}
</script>
